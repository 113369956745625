
import { AudioPlayback } from '@/services/audio/audioPlayback';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  shallowRef,
  watch,
} from 'vue';
import IconButton from '@/components/molecules/IconButton.vue';
import debounce from 'lodash/debounce';
import { chooseNearest, roundTo } from '@/utilities/utilityFunctions';

const playbackRates = [1, 1.5, 2, 0.5];

export default defineComponent({
  name: 'AudioPlayer',
  props: {
    src: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Number,
      default: 1,
    },
    state: {
      type: String as PropType<'play' | 'stop' | 'pause' | 'record' | ''>,
      default: '',
    },
  },
  emits: {
    'update:autoPlay': null,
    'update:speed': null,
  },
  setup(props, ctx) {
    // Element refs
    const playerEl = ref<HTMLElement>();
    const audioEl = ref<HTMLAudioElement>();

    // Audio state
    const audioPlayback = shallowRef<AudioPlayback | undefined>(undefined);
    function updateAudioPlayback() {
      if (audioEl.value) {
        audioPlayback.value = new AudioPlayback(audioEl.value);
        if (props.speed && audioPlayback.value) {
          audioPlayback.value.playbackRate.value =
            chooseNearest(props.speed, playbackRates) || 1;
        }
      }
    }
    onMounted(() => {
      updateAudioPlayback();
    });

    watch(audioEl, updateAudioPlayback);

    /**
     * The percent between 0 and 1 of the audio track's playback
     */
    const percentPlayed = computed(() => {
      return (
        (audioPlayback.value?.seconds.value || 0) /
        (audioPlayback.value?.duration.value || 1)
      );
    });

    /**
     * The seconds played of the audio element
     */
    const secondsDisplay = computed(() => {
      const origSeconds = audioPlayback.value?.seconds.value || 0;
      const minutes = Math.floor(origSeconds / 60);
      const seconds = Math.round(origSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    });

    /**
     * The string to display in the playback rate button
     */
    const rateDisplay = computed(() => {
      const value = roundTo(audioPlayback.value?.playbackRate.value || 1, 1);
      const splitValue = value.toString().split('.');
      return {
        integer: splitValue.at(0),
        decimal: splitValue.at(1),
      };
    });

    /**
     * Function to adjust the playback rate
     */
    function adjustPlaybackRate() {
      if (audioPlayback.value) {
        const index = playbackRates.indexOf(audioPlayback.value.playbackRate.value);
        if (index === -1) {
          audioPlayback.value.playbackRate.value = playbackRates[0];
        } else {
          audioPlayback.value.playbackRate.value =
            playbackRates[(index + 1) % playbackRates.length];
        }

        ctx.emit('update:speed', audioPlayback.value.playbackRate);
      }
    }

    // Dragging state
    const dragging = ref(false);
    const wasPlaying = ref(false);
    const dragPercent = ref(-1);

    /**
     * Calculate the percent the mouse or touch event is to the left of the starting box.
     */
    function calculatePercent(e: MouseEvent | TouchEvent) {
      const target = e.currentTarget;
      const x = e instanceof MouseEvent ? e.pageX : e.touches.item(0)?.pageX;
      if (target instanceof HTMLElement && x !== undefined) {
        const targetRect = target.getBoundingClientRect();
        return (x - targetRect.left) / (targetRect.right - targetRect.left);
      }
    }

    /**
     * Handle a drag mouse or touch event for progress bar
     */
    function drag(e: MouseEvent | TouchEvent) {
      if (!dragging.value || props.disabled) return;
      const dragAmount = calculatePercent(e);
      if (typeof dragAmount === 'number') {
        dragPercent.value = dragAmount;
        if (audioPlayback.value) {
          audioPlayback.value.seconds.value =
            audioPlayback.value.duration.value * dragPercent.value;
        }
      }
    }

    /**
     * Handle a touch up or mouse up event for progress bar
     */
    function up(e: MouseEvent | TouchEvent) {
      drag(e);
      dragging.value = false;
      if (wasPlaying.value) {
        wasPlaying.value = false;
        audioPlayback.value?.play();
      }
      dragPercent.value = -1;
      document.removeEventListener('touchend', up);
      document.removeEventListener('mouseup', up);
    }

    /**
     * Handle a touch down or mouse down event for progress bar
     */
    function down(e: MouseEvent | TouchEvent) {
      // if (props.disabled) return;
      playerEl.value?.focus();
      if (window.TouchEvent && e instanceof TouchEvent && e.touches.length > 1) return;
      dragging.value = true;
      if (audioPlayback.value?.playing.value) {
        wasPlaying.value = true;
        audioPlayback.value?.pause();
      }
      drag(e);
      document.addEventListener('touchend', up);
      document.addEventListener('mouseup', up);
    }

    /**
     * Calculate the positive or negative time to seek
     */
    function seekTime(forward: boolean, fast: boolean, totalDuration: number) {
      return (
        Math.max(1, Math.min(10, totalDuration * (fast ? 0.1 : 0.05))) *
        (forward ? 1 : -1)
      );
    }

    /**
     * Handle keyboard events for player
     */
    function handleKey(e: KeyboardEvent) {
      if (props.disabled) return;
      const key = e.key;
      switch (key) {
        case ' ':
        case 'Enter':
          audioPlayback.value?.playing.value
            ? audioPlayback.value?.pause()
            : audioPlayback.value?.play();
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          if (audioPlayback.value) {
            const playing = audioPlayback.value?.playing.value;
            if (playing) {
              audioPlayback.value?.pause();
            }
            const seekAmount = seekTime(
              key === 'ArrowRight',
              e.ctrlKey,
              audioPlayback.value.duration.value || 0
            );
            const newValue = Math.min(
              Math.max(0, audioPlayback.value.seconds.value + seekAmount),
              audioPlayback.value.duration.value
            );
            audioPlayback.value.seconds.value = newValue;

            if (playing) {
              audioPlayback.value?.play();
            }
          }
          break;
        default:
          break;
      }
    }

    const debounceKeyHandler = debounce(handleKey, 100, {
      leading: true,
      trailing: false,
    });

    watch(
      () => props.src,
      () => {
        if (props.autoPlay && audioPlayback.value) {
          setTimeout(() => {
            if (props.autoPlay && audioPlayback.value && props.src) {
              audioPlayback.value.play();
              ctx.emit('update:autoPlay', false);
            }
          }, 100);
        }
        if (props.speed && audioPlayback.value) {
          setTimeout(() => {
            if (props.speed && audioPlayback.value) {
              audioPlayback.value.playbackRate.value =
                chooseNearest(props.speed, playbackRates) || 1;
            }
          }, 100);
        }
      }
    );

    watch(
      () => props.speed,
      () => {
        if (props.speed && audioPlayback.value) {
          audioPlayback.value.playbackRate.value =
            chooseNearest(props.speed, playbackRates) || 1;
        }
      }
    );

    watch(
      () => props.state,
      () => {
        switch (props.state) {
          case 'stop':
            audioPlayback.value?.stop();
            break;
          case 'play':
            audioPlayback.value?.play();
            break;
          case 'pause':
            audioPlayback.value?.pause();
            break;
          default:
            break;
        }
      }
    );

    return {
      playerEl,
      audioEl,
      audioPlayback,
      percentPlayed,
      secondsDisplay,
      rateDisplay,
      adjustPlaybackRate,
      dragging,
      wasPlaying,
      dragPercent,
      down,
      up,
      drag,
      debounceKeyHandler,
    };
  },
  components: { IconButton },
});
