
import { defineComponent } from 'vue';
import Icon from '@/components/content/Icon.vue';
import Button from '@/components/form/Button.vue';

export default defineComponent({
  name: 'IconButton',
  components: { Icon, Button },
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
});
