import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, { class: "qa-icon-button" }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, {
        name: _ctx.icon,
        class: "qa-ib-icon"
      }, null, 8, ["name"])
    ]),
    _: 1
  }))
}